<template>
  <div class="d-flex flex-wrap mb-3 mx-0">
    <div class="d-flex align-items-start pr-3" style="min-width: 245px">
      <span class="w-100 my-2 py-1 pr-2">
        {{ $t(`week.${day}`) }}
      </span>
      <Switcher
        v-model="hasService"
        class="my-2"
        :on-label="$t('label.open')"
        :off-label="$t('label.closed')"
        @change="setOperation()"
      />
    </div>
    <div class="pl-1">
      <div v-if="slotTimes.length">
        <UpdateSlotTimes
          v-for="(slots, index) in slotTimes"
          :key="slots.id"
          :slots="slots"
          :outlet-id="outletId"
          :weekday="weekday"
          :can-add-slot-times="index === 0"
          :disabled-add-slot-times="canAddSlotTimes"
          @created="canAddSlotTimes = true"
          @updated="updated"
          @deleted="destroy"
        />
      </div>
      <AddSlotTimes
        v-if="canAddSlotTimes"
        :outlet-id="outletId"
        :weekday="weekday"
        :ordering-service="orderingService"
        @created="created"
      />
    </div>
  </div>
</template>

<script>
import AddSlotTimes from "./slot-times/Create.vue";
import UpdateSlotTimes from "./slot-times/Update.vue";
import REMOVE_SLOT_TIME from "@/modules/outlets/graphql/RemoveOpeningHourSlot.gql";
import { Switcher, Toast } from "@ds";
import {
  isEmpty,
  indexOf,
  find,
  pathOr,
  sortWith,
  ascend,
  prop,
  has,
} from "ramda";
import { isNilOrEmpty } from "ramda-extension";

export default {
  name: "Weekday",
  components: {
    Switcher,
    AddSlotTimes,
    UpdateSlotTimes,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    weekday: {
      type: String,
      required: true,
    },
    orderingService: {
      type: String,
      required: true,
    },
    openingHours: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    slotTimes: [],
    hasService: false,
    canAddSlotTimes: false,
    errors: {},
  }),
  computed: {
    day() {
      return this.weekday.toLowerCase().slice(0, 3);
    },
    isOpen() {
      return !isEmpty(this.openingHours);
    },
  },
  mounted() {
    (this.hasService = this.isOpen), (this.slotTimes = this.openingHours);
  },
  methods: {
    setOperation() {
      if (this.hasService) {
        this.canAddSlotTimes = true;
      } else {
        this.errors = null;
        this.removeAll();
      }
    },
    async removeAll() {
      await this.slotTimes.forEach(async (slotTimes) => {
        await this.remove(slotTimes.id);
      });

      if (isNilOrEmpty(this.errors)) {
        new Toast().create({
          type: "success",
          title: this.$t("label.success"),
          text: this.$t("message.record_removed"),
        });
        this.slotTimes = [];
        this.canAddSlotTimes = false;
      } else {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("message.general_failure"),
        });
      }
    },
    orderSlots() {
      this.slotTimes = sortWith([ascend(prop("startTime"))], this.slotTimes);
    },
    created(slot) {
      this.slotTimes.push(slot);
      this.orderSlots();
      this.canAddSlotTimes = false;
    },
    updated(slot) {
      const index = indexOf(
        find((obj) => obj.id === slot.id, this.slotTimes),
        this.slotTimes
      );
      this.slotTimes[index] = slot;
      this.orderSlots();
    },
    destroy(slotId) {
      const index = indexOf(
        find((obj) => obj.id === slotId, this.slotTimes),
        this.slotTimes
      );
      this.slotTimes.splice(index, 1);
    },
    remove(slotTimesId) {
      try {
        const res = this.$apollo.mutate({
          mutation: REMOVE_SLOT_TIME,
          variables: {
            outletId: this.outletId,
            openingHourId: slotTimesId,
          },
        });

        if (has("errors", res)) {
          throw res.errors;
        }

        this.errors = null;
      } catch (error) {
        const errors = error.graphQLErrors ?? error;
        this.errors = pathOr(null, ["0", "extensions", "validation"], errors);
      }
    },
  },
};
</script>
