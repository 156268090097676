import { ref, Ref } from "@vue/composition-api";
import { isEmpty, isNil, path, pathOr } from "ramda";

interface ErrorContainer {
  [prop: string]: Array<string>;
}

// eslint-disable-next-line
export const useFormErrors = () => {
  const errors = ref<ErrorContainer>({});

  const setErrors = (formErrors: ErrorContainer): void => {
    errors.value = formErrors;
  };

  const getErrors = (): Ref<ErrorContainer> => {
    return errors;
  };

  const clearErrors = (): void => {
    errors.value = {};
  };

  const clearErrorFor = (key: string): void => {
    if (!errors.value[key]) return;
    delete errors.value[key];
  };

  const messageErrorFor = (key: string): string | null => {
    if (!errors.value[key]) return null;

    return errors.value[key][0] ?? null;
  };

  const hasErrorFor = (key: string): boolean => {
    if (!errors.value[key]) return false;

    return errors.value[key].length > 0 ?? false;
  };

  const hasErrors = (): boolean => {
    return !isEmpty(errors.value) && !isNil(errors.value);
  };

  const gqlErrorMessageExtractor = (
    error: ErrorContainer,
    key: string
  ): string => {
    const errorValidation: string | undefined = path(
      ["graphQLErrors", "0", "extensions", "validation", key, "0"],
      error
    );

    const errorMessage: string = pathOr(
      "",
      ["graphQLErrors", "0", "message"],
      error
    );

    return errorValidation ?? errorMessage;
  };

  const gqlValidationErrorsExtractor = (error: Error): ErrorContainer => {
    return pathOr(
      {},
      ["graphQLErrors", "0", "extensions", "validation"],
      error
    );
  };

  return {
    gqlErrorMessageExtractor,
    gqlValidationErrorsExtractor,
    getErrors,
    setErrors,
    clearErrors,
    clearErrorFor,
    messageErrorFor,
    hasErrorFor,
    hasErrors,
  };
};
