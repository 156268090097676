

























import ADD_SLOT_TIME from "@/modules/outlets/graphql/AddOpeningHourSlot.gql";
import { defineComponent, computed, ref } from "@vue/composition-api";
import { FormControl, SelectTime, Toast, Icon } from "@/design-system";
import { useFormErrors } from "@/composable";
import { translate } from "@/i18n";

export default defineComponent({
  name: "AddSlotTimes",
  components: {
    FormControl,
    SelectTime,
    Icon,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    weekday: {
      type: String,
      required: true,
    },
    orderingService: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const isLoading = ref<boolean>(false);
    const slot = ref<Record<string, any>>({
      startTime: null,
      endTime: null,
    });

    const hasErrors = computed((): Boolean => {
      return hasErrorFor("startTime") || hasErrorFor("endTime");
    });

    const messageError = computed(() => {
      return messageErrorFor("startTime") || messageErrorFor("endTime");
    });

    // init form errors
    const {
      clearErrors,
      setErrors,
      hasErrorFor,
      messageErrorFor,
      gqlValidationErrorsExtractor,
    } = useFormErrors();

    // add slotTime
    const add = async () => {
      isLoading.value = true;

      try {
        const payload = {
          outletId: props.outletId,
          dayOfWeek: props.weekday,
          orderingService: props.orderingService,
          startTime: slot.value.startTime,
          endTime: slot.value.endTime,
        };

        const res = await context.root.$apollo.mutate({
          mutation: ADD_SLOT_TIME,
          errorPolicy: "none",
          variables: payload,
        });

        clearErrors();

        new Toast().create({
          type: "success",
          title: translate("label.success"),
          text: translate("message.record_created"),
        });

        context.emit("created", res.data.addOpeningHourSlotForDay);
      } catch (error: any) {
        setErrors(gqlValidationErrorsExtractor(error));

        new Toast().create({
          type: "error",
          title: translate("label.oops"),
          text: translate("message.general_failure"),
        });
      } finally {
        isLoading.value = false;
      }
    };

    // change slotTime
    const changeSlot = (time: number, item: string) => {
      slot.value[item] = time || "";

      if (slot.value.startTime && slot.value.endTime) {
        add();
      }
    };

    return {
      // state
      isLoading,
      slot,

      // actions
      hasErrors,
      messageError,
      add,
      changeSlot,
    };
  },
});
