



























































import UPDATE_SLOT_TIME from "@/modules/outlets/graphql/UpdateOpeningHourSlot.gql";
import REMOVE_SLOT_TIME from "@/modules/outlets/graphql/RemoveOpeningHourSlot.gql";
import {
  defineComponent,
  computed,
  ref,
  onMounted,
} from "@vue/composition-api";
import { FormControl, SelectTime, Button, Icon, Toast } from "@/design-system";
import { useFormErrors } from "@/composable";
import { translate } from "@/i18n";

export default defineComponent({
  name: "UpdateSlotTimes",
  components: {
    SelectTime,
    FormControl,
    Button,
    Icon,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    weekday: {
      type: String,
      required: true,
    },
    slots: {
      type: Object,
      required: true,
    },
    canAddSlotTimes: {
      type: Boolean,
      default: false,
    },
    disabledAddSlotTimes: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isLoading = ref<boolean>(false);
    const slotTimes = ref<Record<string, any>>({
      startTime: null,
      endTime: null,
    });

    const isDisabled = computed((): Boolean => {
      return !slotTimes.value.startTime;
    });

    const disabledAdd = computed((): Boolean => {
      if (!slotTimes.value.startTime || !slotTimes.value.endTime) {
        return true;
      }
      return props.disabledAddSlotTimes;
    });

    const hasErrors = computed((): Boolean => {
      return hasErrorFor("startTime") || hasErrorFor("endTime");
    });

    const messageError = computed(() => {
      return messageErrorFor("startTime") || messageErrorFor("endTime");
    });

    onMounted(() => {
      slotTimes.value = props.slots;
    });

    // init form errors
    const {
      clearErrors,
      setErrors,
      hasErrorFor,
      messageErrorFor,
      gqlValidationErrorsExtractor,
    } = useFormErrors();

    // update slotTime
    const update = async () => {
      isLoading.value = true;

      try {
        const payload = {
          outletId: props.outletId,
          openingHourId: slotTimes.value.id,
          startTime: slotTimes.value.startTime,
          endTime: slotTimes.value.endTime,
        };

        const res = await context.root.$apollo.mutate({
          mutation: UPDATE_SLOT_TIME,
          errorPolicy: "none",
          variables: payload,
        });

        clearErrors();

        new Toast().create({
          type: "success",
          title: translate("label.success"),
          text: translate("message.record_updated"),
        });

        context.emit("updated", res.data);
      } catch (error: any) {
        setErrors(gqlValidationErrorsExtractor(error));

        new Toast().create({
          type: "error",
          title: translate("label.oops"),
          text: translate("message.general_failure"),
        });
      } finally {
        isLoading.value = false;
      }
    };

    // remove slotTime
    const remove = async () => {
      isLoading.value = true;

      try {
        await context.root.$apollo.mutate({
          mutation: REMOVE_SLOT_TIME,
          errorPolicy: "none",
          variables: {
            outletId: props.outletId,
            openingHourId: slotTimes.value.id,
          },
        });

        clearErrors();

        new Toast().create({
          type: "success",
          title: translate("label.success"),
          text: translate("message.record_removed"),
        });

        context.emit("deleted", slotTimes.value.id);
      } catch (error: any) {
        setErrors(gqlValidationErrorsExtractor(error));

        new Toast().create({
          type: "error",
          title: translate("label.oops"),
          text: translate("message.general_failure"),
        });
      } finally {
        isLoading.value = false;
      }
    };

    // change slotTime
    const changeSlot = (time: number, item: string) => {
      slotTimes.value[item] = time || "";

      if (slotTimes.value.startTime && slotTimes.value.endTime) {
        update();
      }
    };

    return {
      // state
      isLoading,
      slotTimes,

      // actions
      isDisabled,
      disabledAdd,
      hasErrors,
      messageError,
      update,
      remove,
      changeSlot,
    };
  },
});
