import { ref, watch, isRef, Ref } from "@vue/composition-api";

export const useDebounceValue = (
  value: any,
  delay: number = 1000
): {
  debouncedValue: any;
  isLoading: Ref<boolean>;
} => {
  const debouncedValue = ref(isRef(value) ? value.value : value);
  const isLoading = ref<boolean>(false);

  watch(value, (newValue, _, clean) => {
    isLoading.value = true;

    const theTimeout = setTimeout(() => {
      debouncedValue.value = newValue;
      isLoading.value = false;
    }, delay);

    clean(() => clearTimeout(theTimeout));
  });
  return { debouncedValue, isLoading };
};
